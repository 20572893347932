
					@import './src/sass/variables';
					@import './src/sass/mixins';
				




















































h2 {
	margin-top: $default_padding * 2;
}
